























































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import moment from "moment";

export default defineComponent({
  props: {
    participantAccommodation: {
      type: Object,
      required: false,
      default: () => ({
        roomId: "",
        from: "",
        to: "",
        roommate: null,
        roommateCode: null,
        reservationDate: "",
      }),
    },
    currency: {
      type: String,
      required: false,
      default: "",
    },
    fromDate: {
      type: String,
      required: false,
      default: "",
    },
    toDate: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root, emit }) {
    const state = reactive({
      loading: false,
      loaded: false,
      hotels: [],
      isActive: props.participantAccommodation ? true : false,
      error: false as boolean | number,
      success: false,
      isRoommateCode: "without roommate",
      minToDate: "",
    });

    const model = reactive({
      roomId: props.participantAccommodation
        ? props.participantAccommodation?.room?.id
        : null,
      from: props.participantAccommodation
        ? props.participantAccommodation?.from
        : null,
      to: props.participantAccommodation
        ? props.participantAccommodation?.to
        : null,
      roommate: props.participantAccommodation
        ? props.participantAccommodation?.roommate
        : null,
      roommateCode: props.participantAccommodation
        ? props.participantAccommodation?.roommateCode
        : null,
      reservationDate: props.participantAccommodation
        ? props.participantAccommodation?.reservationDate
        : null,
    });

    const checkRoommateType = () => {
      const tempArray = state.hotels
        .map((el: any) => el.rooms)
        .flat()
        .map((item: any) => ({ id: item.id, roommate: item.roommate }));

      const element: any = tempArray.filter(
        (el: any) => el.id === model.roomId
      );
      state.isRoommateCode =
        element[0].roommate === "name and surname"
          ? "name and surname"
          : element[0].roommate === "participant code"
          ? "participant code"
          : "without roommate";
    };

    const getMinFromDate = () => {
      const date = new Date(model.from);
      const tempDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      );
      state.minToDate = moment(tempDate).format("YYYY-MM-DD");
    };

    const fetchHotels = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/hotel`)
        .then(({ data: { hotels } }) => {
          state.hotels = hotels;
          checkRoommateType();
          getMinFromDate();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.hotels = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchHotels);

    watch(
      () => state.isActive,
      () => {
        if (state.isActive === false) {
          model.roomId = "";
          model.from = "";
          model.to = "";
          model.roommate = "";
          model.roommateCode = null;
          model.reservationDate = "";
        }
      }
    );

    watch(() => model.from, getMinFromDate);

    watch(() => model.roomId, checkRoommateType);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc(
            "panel.event.participant.edit.serviceAmountExceeded"
          )}`;
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 408:
          return `${root.$tc(
            "panel.event.participant.edit.accommodationDateError"
          )}`;
        case 409:
          return `${root.$tc("layout.errors.participantAlreadyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const accommodationData = {
        roomId: model.roomId || null,
        from: model.from,
        to: model.to,
        roommate: model.roommate || null,
        roommateCode: model.roommateCode || null,
        reservationDate: model.reservationDate,
      };

      const data = {
        accommodationData: state.isActive ? accommodationData : null,
      };

      state.loading = true;

      axiosInstance
        .put(`participant/${root.$route.params.ptid}/accommodation`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "event.participant.editInfo.participantUpdateSuccessfull"
            ),
          });
          emit("fetch");
        })
        .catch((error) => {
          state.error = error.response.status;
          console.log(error);
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return {
      state,
      model,
      onSubmit,
    };
  },
});
